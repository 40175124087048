const isUrlString = (value) => {
    const urlRegExp = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i;
    return urlRegExp.test(value);
};
export const validateUrlString = (value) => {
    if (!isUrlString(value)) {
        throw new Error(`Invalid URL: ${value}`);
    }
    return value;
};
export var ActionType;
(function (ActionType) {
    ActionType["LINK"] = "link";
    ActionType["CALLBACK"] = "callback";
    ActionType["EMAIL"] = "email";
    ActionType["PHONE"] = "phone";
})(ActionType || (ActionType = {}));
