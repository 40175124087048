export const injectBrainfishInWindow = (brainfish) => {
    if (typeof window === 'undefined')
        return;
    if (window.Brainfish) {
        window.Brainfish = { ...window.Brainfish, ...brainfish };
    }
    else {
        window.Brainfish = { ...brainfish };
    }
};
